<template>
  <div class="contact nav-spcr py-5">
    <div class="row h-100">
      <div class="col-12 col-lg-6 col-xl-5 l">
      </div>
      <div class="col-12 col-lg-6 xol-xl-7">
        <div class="container text-left ml-lg-0">

          <h1>Contact Us</h1>
          <p class="text-gray-light">We love to work with creative and open-minded people.<br class="d-none d-lg-block"> Please reach out and send
            us a message!</p>
          <b-form v-on:submit.prevent="onSubmit" v-if="notSubmitted" ref="contact-form">

            <b-form-group id="input-email" label="Email address:" label-for="input-email">
              <b-form-input id="input-email" v-model="form.email" type="email" required placeholder="Enter email">
              </b-form-input>
            </b-form-group>

            <b-form-group id="input-name" label="Your Name:" label-for="input-name">
              <b-form-input id="input-name" v-model="form.name" required placeholder="Enter name"></b-form-input>
            </b-form-group>

            <b-form-group id="input-phone" label="Your Phone Number:" label-for="input-phone">
              <b-form-input id="input-phone" v-mask="'(###) ###-####'" v-model="form.phone" type="text" required
                placeholder="Enter phone number"></b-form-input>
            </b-form-group>

            <b-form-group id="input-message" label="Your Message:" label-for="input-message">
              <b-form-textarea id="input-message" v-model="form.message" placeholder="Enter something..." rows="6"
                required></b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary" class="d-block w-100">Submit</b-button>
          </b-form>
          <p v-else class="text-success  lead">We got your message. The SYPHER Team will be in contact shortly⚡️</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';


export default {
  name: 'contact',
  data: function() {
      return {
        form: {
          email: '',
          name: '',
          phone: null,
          message: ''
        },
        notSubmitted: true
      }
  },
  methods: {
      onSubmit(evt) {

      evt.preventDefault();

      if (this.$refs['contact-form'].checkValidity() !== false) {
        axios({
            method: 'post',
            url: 'https://us-central1-sypher-landing.cloudfunctions.net/contact',
            data: {
              form: this.form
            }
          })
          .then((response) => {
            if(response.status == 200)
            {
              this.notSubmitted = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      }
  }
  
}
</script>